<template>
  <div class="home">
    <heade @checkIndex="indexFun"></heade>
    <div>
      <!-- <index v-if="index == 1"></index>
      <projects v-if="index == 2"  @checkIndex="indexFun"></projects> -->
      <router-view/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import heade from '@/components/heade.vue'
// import index from '@/components/index.vue'
// import direct from '@/components/direct.vue'
// import news from '@/components/news.vue'
// import projects from '@/components/projects.vue'


export default {
  name: 'Home',
  components: {
    heade,
    // projects,
    // index,
    // direct
  
  },
  data(){
    return{
      index:1
    }
  },
  mounted(){},
  methods:{
    indexFun(index){
      console.log(index)
      this.index = index
    }
  }
}
</script>
