<template>
  <div class="header">
    <div class="header_box container">
      <div class="img"><img src="../assets/cg_logo.png" /></div>
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1" @click="go('index')">首页</el-menu-item>
          <el-menu-item index="2" @click="go('projects')">优秀项目案例</el-menu-item>
          <!-- <el-menu-item index="3">新闻</el-menu-item>
          <el-menu-item index="4">指南简介</el-menu-item>
          <el-menu-item index="5">立项名单</el-menu-item>
          <el-menu-item index="6">优秀项目案例</el-menu-item>
          <el-menu-item index="7">线上展示</el-menu-item>
          <el-menu-item index="8">入驻说明</el-menu-item> -->

        </el-menu>
      </div>
      <!-- <div class="box-right">项目申请平台</div> -->
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "heade",
  props: {},
  data(){
    return{
      activeIndex:'1'
    }
  },
mounted(){

},
  mounted(){
    debugger
    if((window.location.href).indexOf('projects')!=-1){
      this.activeIndex='2'
    }
    else{
      this.activeIndex='1'
    }
  },
  methods:{
    handleSelect(index){
      console.log(index)
      this.$emit('checkIndex',index)
      document.body.scrollTop=document.documentElement.scrollTop=0

    },
    go(url){
      this.$router.push({name:url})
    }
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
.header {
  width: 100%;
  height: 60px;
  box-shadow: 0 0 4px 1px #ccc;
  background:#fff;
  position: sticky;
    top: 0;
    z-index: 9;
  .header_box {
    display: flex;
    justify-content: flex-start;

    margin: 0 auto;
    // padding-top: 13px;
    .img {
      width: 100px;
      margin-top:16px;
      margin-right:30px;
      img {
        width: 100%;
      }
    }
    .box-right{
      margin-top:16px;
    }
  }
}
</style>
  